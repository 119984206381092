<template>
  <div class="w-full flex flex-col" :class="{ relative: loading }">
    <div :class="{ 'table--responsive': responsive }">
      <slot />
    </div>
    <div
      v-show="loading"
      class="has-centered-item"
      :class="fixHeight ? 'middle--height-loading' : 'h-full'"
    >
      <base-spinner size="40" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    responsive: Boolean,
    loading: Boolean,
    fixHeight: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.table--responsive {
  @apply overflow-x-auto;
}
.middle--height-loading {
  height: 450px;
}
</style>
