<template>
  <table-loading fix-height :loading="loading" responsive>
    <table class="sprint-table">
      <thead v-if="sprints.length">
        <tr>
          <th scope="col">
            <span class="hidden">Intro</span>
          </th>
          <th scope="col" class="table__column--user-cell">
            <div class="pl-8">{{ $t('input.complete') }}</div>
          </th>
          <th scope="col" class="table__column--date-cell">
            {{ $t('input.start_date') }}
          </th>
          <th scope="col" class="table__column--date-cell">
            {{ $t('input.end_date') }}
          </th>
          <th scope="col" class="table__column--goal-cell">
            {{ $t('sprint.goals') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="sprint in sprints"
          :key="sprint.id"
          @click="selectSprint(sprint)"
        >
          <td class="border-r">
            <div class="pr-10">
              <header class="text-lg text-brand-blue font-medium mb-2">
                {{ sprint.name }}
              </header>
              <div class="divide-x -mx-2 whitespace-no-wrap">
                <span class="px-2">
                  {{ $t('agile.boardComplete.title') }}:
                  <span class="font-medium">
                    {{ sprint.numberOfCompletedTasks }}
                  </span>
                </span>
                <span class="px-2">
                  {{ $t('agile.boardComplete.close') }}
                  {{ formatDate(sprint.completedAt) }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div class="inline-flex w-full pl-8 table__column--user-cell">
              <info-user
                v-if="sprint.completedByUser"
                :user="sprint.completedByUser"
                size="small"
              />
            </div>
          </td>
          <td class="whitespace-no-wrap">
            <div class="pt-1">{{ formatDate(sprint.startAt) }}</div>
          </td>
          <td class="whitespace-no-wrap">
            <div class="pt-1">{{ formatDate(sprint.endAt) }}</div>
          </td>
          <td>
            <p class="text-2-line">
              {{ sprint.goal }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </table-loading>
</template>

<script>
import moment from 'moment';
import TableLoading from './table-loading.vue';
import InfoUser from '../info/info-user.vue';
export default {
  components: {
    TableLoading,
    InfoUser
  },
  props: {
    loading: Boolean,
    sprints: {
      type: Array
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    selectSprint(sprint) {
      this.$router.push({
        name: 'Board-Completed-Sprint-Id',
        params: {
          sprint_id: sprint.id
        }
      });
    }
  }
};
</script>

<style scoped>
.table__column--user-cell {
  max-width: calc(158px + 4rem);
}
.table__column--date-cell {
  width: 15%;
}
.table__column--goal-cell {
  min-width: 286px;
}
</style>
