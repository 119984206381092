<template>
  <base-empty
    v-if="shouldShowEmpty"
    class="flex-1 has-centered-item"
    :title="$t('agile.emtyPage.compleate.title')"
    :description="$t('agile.emtyPage.compleate.description')"
          
    :image="require('@/assets/image/no-completed-sprint.svg')"
  />
  <div v-else class="flex-1 flex flex-col">
    <header class="flex items-center justify-between agile-content__container">
      <div>
        <h1 class="text-2xl">{{ $t('agile.compleate.compleate') }}</h1>
        <caption class="block text-gray-caption text-left text-sm">
          {{
            $t('agile.compleate.description')
          }}
        </caption>
      </div>
      <base-input-text
        v-model="queryString"
        class="search--completed-task input-pr-8"
        inner-class="base-input--gray"
        :placeholder="$t('agile.compleate.placeholder')"
        icon-fa="search"
        :icon-right="queryString ? 'IconClose' : null"
        @icon-right-click="queryString = ''"
      />
    </header>
    <section>
      <div
        v-if="sprints.length || queryLoading"
        class="agile-content__container flex-1 flex"
      >
        <table-sprint-completed
          :loading="queryLoading || shouldQueryNextPage"
          :sprints="sprints"
        />
      </div>
      <div v-else>
        <base-empty
          :image="require('@/assets/image/no-sprint-search.svg')"
          :title="$t('agile.emtyPage.search.title')"
        >
          <div class="text-2-line text-base font-normal">
            <span>
              {{ $t('agile.emtyPage.search.description') }}
            </span>
            <strong class="text-black "> "{{ queryString }}" </strong>
          </div>
        </base-empty>
      </div>
    </section>
  </div>
</template>

<script>
import apisSprints from '@/services/apis-sprints';
import BoardUtils from '@/mixins/BoardUtils';
import MembersVisible from '@/mixins/MembersVisible';
import QueryListMixin from '@/mixins/QueryListMixin';
import TableSprintCompleted from '@/components/table/table-sprint-completed.vue';
import eventBus from '@/services/eventBus';
export default {
  mixins: [BoardUtils, QueryListMixin, MembersVisible],
  components: { TableSprintCompleted },
  data() {
    return {
      querySearch: '',
      fetchCounts: 0
    };
  },
  computed: {
    sprints() {
      return this.queryList;
    },
    shouldShowEmpty() {
      return this.fetchCounts === 1 && this.sprints.length === 0;
    }
  },
  created() {
    eventBus.$on('scroll-bottom', this.scrollBottom);
  },
  beforeDestroy() {
    eventBus.$off('scroll-bottom', this.scrollBottom);
  },

  watch: {
    sprints() {
      this.fetchCounts++;
    }
  },
  methods: {
    async queryFetcher({ params }) {
      const result = await apisSprints.getComplete(params);
      return result;
    },
    scrollBottom() {
      if (this.shouldQueryNextPage) this.addQueryNextPage();
    }
  }
};
</script>

<style></style>
